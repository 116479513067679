.panel-card-container {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  min-width: max-content;
  margin: 1rem;
}
.panel-header-title {
  font-size: 2.2rem;
  font-weight: bolder;
}

.card-empty {
  color: rgb(210, 210, 210) !important;
  border-color: rgb(210, 210, 210) !important;
  border-bottom-color: rgb(210, 210, 210) !important;
}

.card-empty-dark {
  color: rgb(84, 84, 84) !important;
  border-color: rgb(84, 84, 84) !important;
  border-bottom-color: rgb(84, 84, 84) !important;
}

.card-field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-field-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 5px;
}
.card-field-text {
  font-size: 2rem;
  font-weight: bold;
}

.calculate-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.calculate-row-item {
  display: flex;
  flex-direction: column;
  flex-basis: 49%;
}
.calculate-avg-title {
  font-weight: lighter;
  font-size: 1.2rem;
  margin-bottom: 7px;
}
.calculate-avg-title-large {
  margin-bottom: -7px;
  font-size: 2rem;
}
.calculate-avg-input {
  margin-bottom: 7px;
}
.calculate-avg-value {
  font-size: 2rem;
  margin-bottom: 7px;
  text-align: center;
}
.calculate-avg-value-large {
  font-size: 2.8rem;
}
.calculate-avg-sub-value {
  font-weight: lighter;
  font-size: 0.8rem;
  margin-top: -10px;
}
.calculate-avg-sub-value-large {
  font-size: 1.2rem;
}

.settings-panel {
  margin: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  flex-basis: 40%;
  min-width: fit-content;
}

.header-month {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-month-title {
  display: flex;
  margin-right: 20px;
  font-size: 1.2rem;
  font-weight: lighter;
}

@media screen and (max-width: 876px) {
  .calculate-row {
    flex-direction: column;
  }
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 105px);
}

.table-pagination {
  padding: 9px 20px 9px 20px;
}
.table-pagination-divider {
  margin: 0px 0px 0px 0px;
}
